import { ChangeEvent, KeyboardEvent, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@shared/lib/components/common/atom/button";
import { TimerSpan } from "@shared/lib/components/common/atom/span/timer-span";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";
import { getFormattedPhoneNumber, phoneRegex } from "@/utils/string";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  useAuthControllerSendVerification,
  useAuthControllerVerifyCode,
} from "@shared/generated/api/fn/kac/auth/auth";
import {
  getUserControllerGetProfileQueryKey,
  useUserControllerModifyUserInfo,
} from "@shared/generated/api/fn/kac/user/user";
import { SendVerificationRequestDtoType } from "@shared/generated/api/model";

interface EditPhoneNumberForm {
  phoneNumber: string;
  verifyNumber?: string;
}

interface EditPhoneNumberProps {
  onEditCancel: () => void;
}

export const EditPhoneNumber = ({ onEditCancel }: EditPhoneNumberProps) => {
  const { t } = useCustomTranslation();
  const { data: profile } = useProfile();
  const queryClient = useQueryClient();

  const [verifyActionCount, setVerifyActionCount] = useState(0);
  const [isVerifyNumberDisabled, setIsVerifyNumberDisabled] =
    useState<boolean>(true);
  const [isSuccessVerify, setIsSuccessVerify] = useState<boolean>(false);

  const schema = yup.object({
    phoneNumber: yup
      .string()
      .trim()
      .matches(phoneRegex, t("invalid_phone_number_format"))
      .required(t("please_enter_your_phone_number"))
      .test(
        "sameProfilePhoneNumber",
        t("same_as_registered_phone_number"),
        (value) => value.replaceAll("-", "") !== profile?.phoneNumber,
      ),
    verifyNumber: yup.string().trim(),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<EditPhoneNumberForm>({
    resolver: yupResolver<EditPhoneNumberForm>(schema),
    mode: "onChange",
  });

  const phoneNumber = watch("phoneNumber");
  const onChangePhoneNumber = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue("phoneNumber", getFormattedPhoneNumber(e.target.value));
    await trigger("phoneNumber");
  };

  const onKeyDownPhoneNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSendVerifyNumber();
    }
  };

  const sendVerifyNumberMutation = useAuthControllerSendVerification({
    mutation: {
      onSuccess: (data) => {
        //에러는 아니지만 인증번호 요청 후 요청 성공하면 인증번호 가이드 문구를 위해 에러를 발생시킨다.
        setError("verifyNumber", {
          type: "SUCCESS",
          message: t("verification_code_sent_check_kakao_talk_sms"),
        });
        setIsVerifyNumberDisabled(false);
        setVerifyActionCount(verifyActionCount + 1);
        clearErrors("phoneNumber");
      },
      onError: (error) => {
        console.error(error);
        const { code, message } = error.response?.data as any;

        if (message) {
          openToast(TOAST_TYPE.ERROR, message);
        }
      },
    },
  });

  const onSendVerifyNumber = async () => {
    const replacePhoneNumber = phoneNumber.replaceAll("-", "");
    sendVerifyNumberMutation.mutate({
      data: {
        identifier: replacePhoneNumber,
        method: "SMS",
        type: SendVerificationRequestDtoType.RESET_PASSWORD,
      },
    });
  };

  const onVerifyNumberEndTime = () => {
    if (!isSuccessVerify) {
      setIsVerifyNumberDisabled(true);
    }
  };

  const verifyNumberCheckMutation = useAuthControllerVerifyCode({
    mutation: {
      onSuccess: () => {
        setIsSuccessVerify(true);
        clearErrors("verifyNumber");
      },
      onError: (e) => {
        console.error(e);
      },
    },
  });

  const verifyNumber = watch("verifyNumber");
  const onVerifyNumberCheck = () => {
    verifyNumberCheckMutation.mutate({
      data: {
        type: SendVerificationRequestDtoType.RESET_PASSWORD,
        method: "SMS",
        identifier: phoneNumber.replaceAll("-", ""),
        verificationCode: verifyNumber!,
      },
    });
  };

  const onKeyDownVerifyNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onVerifyNumberCheck();
    }
  };

  const { accessToken } = useAccessToken();
  const updateProfilePhoneNumberMutation = useUserControllerModifyUserInfo({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, t("phone_number_has_been_modified"));
        await queryClient.invalidateQueries({
          queryKey: getUserControllerGetProfileQueryKey(),
        });
        onEditCancel();
      },
      onError: (error) => {
        console.error(error);
        openToast(TOAST_TYPE.ERROR, t("failed_to_modify_phone_number"));
      },
    },
  });

  const onSubmit: SubmitHandler<EditPhoneNumberForm> = async (data) => {
    updateProfilePhoneNumberMutation.mutate({
      data: {
        phoneNumber: data.phoneNumber.replaceAll("-", ""),
      },
    });
  };

  const onError: SubmitErrorHandler<EditPhoneNumberForm> = async (error) => {
    console.error(error);
  };

  return (
    <EditPhoneNumberContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <EditPhoneNumberFormContainer>
          <ButtonTextFieldContainer>
            <EditPhoneNumberFormTextField
              type="text"
              label={t("phone_number")}
              placeholder={t("phone_number")}
              isError={!!errors.phoneNumber}
              message={errors?.phoneNumber?.message}
              onChange={onChangePhoneNumber}
              onKeyDown={onKeyDownPhoneNumber}
              value={phoneNumber}
              maxLength={13}
              disabled={!isVerifyNumberDisabled}
              endActionButton={
                <FormButton
                  type="button"
                  styleType={BUTTON_TYPE.FILL}
                  // errors.phoneNumber.type !==
                  //   PostKacAuthVerifyNumberSend400Code.E02) ||
                  disabled={
                    (!!errors.phoneNumber && !phoneNumber) ||
                    !isVerifyNumberDisabled
                  }
                  onClick={onSendVerifyNumber}
                >
                  {verifyActionCount > 0
                    ? t("request_again")
                    : t("request_verification_code")}
                </FormButton>
              }
            />
          </ButtonTextFieldContainer>

          <ButtonTextFieldContainer>
            <EditPhoneNumberFormTextField
              type="text"
              label={t("verification_code")}
              placeholder={t("verification_code")}
              isError={!!errors.verifyNumber}
              message={errors?.verifyNumber?.message}
              {...register("verifyNumber")}
              onKeyDown={onKeyDownVerifyNumber}
              maxLength={6}
              disabled={
                isVerifyNumberDisabled ||
                isSuccessVerify ||
                sendVerifyNumberMutation.isPending
              }
              endAdornment={
                !isSuccessVerify &&
                !isVerifyNumberDisabled && (
                  <VerifyTimerSpan onEnd={onVerifyNumberEndTime} />
                )
              }
              endActionButton={
                <FormButton
                  type="button"
                  styleType={BUTTON_TYPE.FILL}
                  disabled={
                    isVerifyNumberDisabled ||
                    (!!errors.verifyNumber &&
                      errors.verifyNumber.type !== "SUCCESS") ||
                    isSuccessVerify ||
                    verifyNumberCheckMutation.isPending ||
                    !verifyNumber
                  }
                  onClick={onVerifyNumberCheck}
                >
                  {isSuccessVerify
                    ? t("verification_complete")
                    : t("verify_code")}
                </FormButton>
              }
            />
          </ButtonTextFieldContainer>
        </EditPhoneNumberFormContainer>

        <EditButtonContainer>
          <CancelButton
            type={"button"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={onEditCancel}
          >
            {t("cancel")}
          </CancelButton>
          <EditButton
            type={"submit"}
            styleType={BUTTON_TYPE.FILL}
            disabled={!isValid || !isSuccessVerify}
          >
            {t("modification_complete")}
          </EditButton>
        </EditButtonContainer>
      </form>
    </EditPhoneNumberContainer>
  );
};

const EditPhoneNumberContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    form {
      gap: 40px;
    }
  }
`;

const EditPhoneNumberFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
`;

const EditPhoneNumberFormTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const ButtonTextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormButton = styled(Button)`
  display: flex;
  padding: 14px 16px;
  align-self: flex-end;
  justify-content: center;
  align-items: center;

  min-width: 117px;

  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }
`;

const VerifyTimerSpan = styled(TimerSpan)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.state.error.default};
`;

const EditButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const EditButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

import { useEffect } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import Link from "next/link";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@shared/lib/components/common/atom/button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  getUserControllerGetProfileQueryKey,
  useUserControllerModifyUserInfo,
} from "@shared/generated/api/fn/kac/user/user";

interface EditMarketingAgreeForm {
  isMarketingAgree?: boolean;
}

interface EditMarketingAgreeProps {
  onEditCancel: () => void;
}

export const EditMarketingAgree = ({
  onEditCancel,
}: EditMarketingAgreeProps) => {
  const { t } = useCustomTranslation();
  const { data: profile } = useProfile();
  const queryClient = useQueryClient();

  const schema = yup.object({
    isMarketingAgree: yup.boolean(),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<EditMarketingAgreeForm>({
    resolver: yupResolver<EditMarketingAgreeForm>(schema),
    mode: "onChange",
  });

  useEffect(() => {
    setValue("isMarketingAgree", profile?.marketingOptIn || false);
  }, [profile]);

  const isMarketingAgree = watch("isMarketingAgree");
  const onChangeAgree = async (
    fieldName: "isMarketingAgree",
    isAgree: boolean,
  ) => {
    setValue(fieldName, isAgree);
    await trigger(fieldName);
  };

  const { accessToken } = useAccessToken();
  const updateProfileAgreeMutation = useUserControllerModifyUserInfo({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: async () => {
        openToast(TOAST_TYPE.SUCCESS, t("marketing_info_modified"));
        await queryClient.invalidateQueries({
          queryKey: getUserControllerGetProfileQueryKey(),
        });
        onEditCancel();
      },
      onError: (error) => {
        console.error(error);
        openToast(TOAST_TYPE.ERROR, t("failed_to_modify_information"));
      },
    },
  });

  const onSubmit: SubmitHandler<EditMarketingAgreeForm> = async (data) => {
    updateProfileAgreeMutation.mutate({
      data: {
        marketingOptIn: data.isMarketingAgree,
      },
    });
  };

  const onError: SubmitErrorHandler<EditMarketingAgreeForm> = async (error) => {
    console.error(error);
  };

  return (
    <EditMarketingAgreeContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <EditMarketingAgreeFormContainer>
          <FormCheckBoxContainer className="choice-agree">
            <CheckBox
              isError={!!errors.isMarketingAgree}
              checked={isMarketingAgree}
              onChange={(e) => {
                onChangeAgree("isMarketingAgree", !isMarketingAgree);
              }}
            />
            <span
              onClick={() =>
                onChangeAgree("isMarketingAgree", !isMarketingAgree)
              }
            >
              {t("marketing_information_consent_optional")}
            </span>
            <Link
              href={""}
              target={"_blank"}
              passHref
            >
              {t("view")}
            </Link>
          </FormCheckBoxContainer>
        </EditMarketingAgreeFormContainer>

        <EditButtonContainer>
          <CancelButton
            type={"button"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={onEditCancel}
          >
            {t("cancel")}
          </CancelButton>
          <EditButton
            type={"submit"}
            styleType={BUTTON_TYPE.FILL}
            disabled={
              !isValid || isMarketingAgree === profile?.marketingOptIn || false
            }
          >
            {t("modification_complete")}
          </EditButton>
        </EditButtonContainer>
      </form>
    </EditMarketingAgreeContainer>
  );
};

const EditMarketingAgreeContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    form {
      gap: 40px;
    }
  }
`;

const EditMarketingAgreeFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
`;

const FormCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &.all-agree {
    span {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;

      line-height: 100%; /* 14px */
      font-weight: 700;
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }

  &.choice-agree {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }

    span {
      cursor: pointer;
      ${({ theme }) => theme.typography.body1.m}
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }

  a {
    margin-left: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const EditButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

const EditButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }

  ${({ theme }) => theme.media.tabletSm} {
    width: 100%;
  }
`;

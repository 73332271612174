import { forwardRef, ReactNode, TextareaHTMLAttributes } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

interface MultiLineTextFieldProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  children?: ReactNode;
  isRequired?: boolean;
  isLengthLabel?: boolean;
  isError?: boolean;
  message?: string;
}
export const MultiLineTextField = forwardRef<
  HTMLTextAreaElement,
  MultiLineTextFieldProps
>((props, textFieldRef) => {
  const {
    children,
    label,
    isRequired,
    isError,
    isLengthLabel,
    className,
    maxLength,
    value,
    message,
    ...reset
  } = props;

  return (
    <MultiLineTextFieldContainer className={className}>
      {label && (
        <MultiLineTextFieldLabel>
          {label}
          {isRequired && <span>*</span>}
        </MultiLineTextFieldLabel>
      )}

      <MultiLineTextFieldInnerContainer>
        <StyledMultiLineTextField
          ref={textFieldRef}
          className={classNames({
            ["is-error"]: isError,
          })}
          maxLength={maxLength}
          value={value}
          {...reset}
        />

        {isLengthLabel && (
          <MultiLineTextFieldValueLengthLabel>
            {`${typeof value === "string" ? value.length : 0}/${maxLength}`}
          </MultiLineTextFieldValueLengthLabel>
        )}
      </MultiLineTextFieldInnerContainer>

      {message && (
        <Message
          className={classNames({
            ["is-error"]: isError,
          })}
        >
          {message}
        </Message>
      )}
    </MultiLineTextFieldContainer>
  );
});

const MultiLineTextFieldContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const MultiLineTextFieldInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MultiLineTextFieldLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.text.high.black};

  span {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.state.error.default};
  }
`;

const StyledMultiLineTextField = styled.textarea`
  outline: none;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;

  width: 100%;
  height: 100%;
  min-height: 98px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};

  resize: none;

  &::placeholder {
    ${({ theme }) => theme.typography.body1.m};
    color: rgba(51, 51, 51, 0.38);
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand.primary.default};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.text.disabled.black};
    background: ${({ theme }) => theme.colors.gray.gray95};
  }

  &.is-error {
    border-color: ${({ theme }) => theme.colors.state.error.default};
  }
`;

const MultiLineTextFieldValueLengthLabel = styled.div`
  display: flex;
  align-self: flex-end;
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;

const Message = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: 0.5px;
  position: relative;
  top: -15px;

  color: ${({ theme }) => theme.colors.icon.inactive.black};

  &.is-error {
    color: ${({ theme }) => theme.colors.state.error.default};
  }
`;

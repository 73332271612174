import { useMemo } from "react";
import classNames from "classnames";
import moment from "moment/moment";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { SkeletonLoading } from "@shared/lib/components/common/atom/loading/skeleton-loading";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { useProfile } from "@/hooks/auth/use-profile";
import { logout } from "@/utils/auth";
import { getFormattedPhoneNumber } from "@/utils/string";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import { AuthType, SocialType } from "@shared/generated/api/model";

interface ProfileInfoProps {
  onActiveEditPassword: () => void;
  onActiveEditPhoneNumber: () => void;
  onActiveEditMarketingAgree: () => void;
  onOpenWithdrawalConfirmModal: () => void;
}

export const ProfileInfo = ({
  onActiveEditPassword,
  onActiveEditPhoneNumber,
  onActiveEditMarketingAgree,
  onOpenWithdrawalConfirmModal,
}: ProfileInfoProps) => {
  const { t } = useCustomTranslation();
  const { data: profile, isLoading } = useProfile();
  const { authType, socialType } = profile || {};

  const snsIcon = useMemo(() => {
    if (authType === AuthType.SOCIAL) {
      if (socialType === SocialType.KAKAO) {
        return ICON_SVG.GRAPHIC.PROFILE.ICON_KAKAO;
      } else if (socialType === SocialType.APPLE) {
        return ICON_SVG.GRAPHIC.PROFILE.ICON_APPLE;
      } else if (socialType === SocialType.GOOGLE) {
        return ICON_SVG.GRAPHIC.PROFILE.ICON_GOOGLE;
      } else if (socialType === SocialType.NAVER) {
        return ICON_SVG.GRAPHIC.PROFILE.ICON_NAVER;
      } else if (socialType === SocialType.FACEBOOK) {
        return ICON_SVG.GRAPHIC.PROFILE.ICON_FACEBOOK;
      }
    }
  }, [authType]);

  const onLogout = async () => {
    await logout({
      redirect: true,
    });
  };

  return (
    <ProfileInfoContainer>
      <ProfileRowContainer>
        <ProfileItemContainer>
          <ProfileLabel>{t("name_real_name")}</ProfileLabel>
          <ProfileContent>
            {isLoading ? <Loading /> : profile?.name}
          </ProfileContent>
        </ProfileItemContainer>
      </ProfileRowContainer>

      <Divider />

      <ProfileRowContainer>
        <ProfileItemContainer>
          <ProfileLabel>{t("email")}</ProfileLabel>
          <ProfileContent>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {authType === AuthType.SOCIAL && snsIcon && (
                  <Icon
                    className="sns-icon"
                    icon={snsIcon}
                  />
                )}
                {profile?.email}
              </>
            )}
          </ProfileContent>
        </ProfileItemContainer>
      </ProfileRowContainer>

      <Divider />

      <ProfileRowContainer>
        {authType && authType === AuthType.EMAIL ? (
          <a
            className={classNames({
              disabled: isLoading,
            })}
            onClick={onActiveEditPassword}
          >
            <ProfileItemContainer>
              <ProfileLabel>{t("password")}</ProfileLabel>
              <ProfileContent>
                {isLoading ? <Loading /> : `*********`}
              </ProfileContent>
            </ProfileItemContainer>
            <Icon icon={ICON_SVG.LINE.SIZE_18.ICON_ARROW_RIGHT} />
          </a>
        ) : (
          <ProfileItemContainer>
            <ProfileLabel>{t("password")}</ProfileLabel>
            <ProfileContent>
              {isLoading ? (
                <Loading />
              ) : (
                <span className="password">
                  {t("cannot_change_password_for_social_login")}
                </span>
              )}
            </ProfileContent>
          </ProfileItemContainer>
        )}
      </ProfileRowContainer>

      <Divider />

      <ProfileRowContainer>
        <a
          className={classNames({
            disabled: isLoading,
          })}
          onClick={onActiveEditPhoneNumber}
        >
          <ProfileItemContainer>
            <ProfileLabel>{t("phone_number")}</ProfileLabel>
            <ProfileContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>{getFormattedPhoneNumber(profile?.phoneNumber || "")}</>
              )}
            </ProfileContent>
          </ProfileItemContainer>
          <Icon icon={ICON_SVG.LINE.SIZE_18.ICON_ARROW_RIGHT} />
        </a>
      </ProfileRowContainer>

      <Divider />

      <ProfileRowContainer>
        <a
          className={classNames({
            disabled: isLoading,
          })}
          onClick={onActiveEditMarketingAgree}
        >
          <ProfileItemContainer>
            <ProfileLabel>{t("marketing_consent")}</ProfileLabel>
            <ProfileContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {profile?.marketingOptIn ? t("agree") : t("disagree")}
                  <span className="marketing-update-at">
                    {profile?.marketingOptInAt &&
                      moment(profile?.marketingOptInAt).format("YYYY-MM-DD")}
                  </span>
                </>
              )}
            </ProfileContent>
          </ProfileItemContainer>
          <Icon icon={ICON_SVG.LINE.SIZE_18.ICON_ARROW_RIGHT} />
        </a>
      </ProfileRowContainer>

      <Divider />

      <ProfileRowContainer>
        <ActionContainer>
          <a onClick={onLogout}>{t("signout")}</a>
          <a onClick={onOpenWithdrawalConfirmModal}>{t("withdrawal")}</a>
        </ActionContainer>
      </ProfileRowContainer>
    </ProfileInfoContainer>
  );
};

const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ProfileRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  a {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.disabled {
      pointer-events: none;
    }
  }
`;

const ProfileItemContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.tabletSm} {
    flex-direction: column;
    gap: 4px;
  }
`;

const ProfileLabel = styled.div`
  flex-shrink: 0;
  width: 250px;

  ${({ theme }) => theme.typography.body1.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
`;

const ProfileContent = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: flex-end;

  ${({ theme }) => theme.typography.body1.r};
  color: ${({ theme }) => theme.colors.text.high.black};

  span {
    &.password {
      color: ${({ theme }) => theme.colors.text.medium.black};
    }

    &.marketing-update-at {
      ${({ theme }) => theme.typography.body3.m};
      color: ${({ theme }) => theme.colors.text.disabled.black};
    }
  }

  svg {
    &.sns-icon {
      margin-left: 0;
      margin-right: 8px;
    }

    align-self: center;
    margin-left: auto;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray.gray95};
`;

const ActionContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;

  a {
    ${({ theme }) => theme.typography.body3.m};
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const Loading = styled(SkeletonLoading)`
  min-height: 22px;
`;
